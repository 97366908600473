.navbar {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: #9baeda;
  color: white;
  width: 100%;
}

.brand-title {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-left: 8rem;   
}

.navbar-links {
  height: 100%;
}

.navbar-links ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  list-style: none;
}

.navbar-links li a {
  /*display: block;*/
  text-decoration: none;
  color: white;
  padding: 1rem;

}

.navbar-links a:hover {
  /*background-color: #F0D290;*/
  color: #F0D290;
  color: var(--primary-color);
  outline: none;
  text-decoration: none;
}

.toggle-button {
  position: absolute;
  top: .75rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

@media (max-width: 800px) {
  .navbar {
      flex-direction: column;
      align-items: flex-start;
      min-width: 100%;
  }

  a.toggle-button{
    margin-top:15%;
    margin-right: 10% }

  .brand-title{
    margin: 10%;
  }

  .toggle-button {
      display: flex;
      width: 5vw;
      height: 3vh;
      margin: 5%;

  }

  .navbar-links {
      display: none;
      width: 100%;
  }

  .navbar-links ul {
      width: 100%;
      flex-direction: column;
  }

  .navbar-links ul li {
      text-align: center;
  }

  .navbar-links ul li a {
      padding: .5rem 1rem;
  }

  .navbar-links.active {
      display: flex;
  }

  .webgif{
    display:flex;
    width: 60%;
    height: auto;
  
  }

}

.imbox{
  display: flex;
  justify-content: center;
}

.containerimage {
  
  max-width: 100%;
  height: auto;
  position: center;
  
}
  :root {
    --primary-color:        #F0D290;
    --white-color:          #ffffff;
    --dark-color:           #171819;
    --about-bg-color:       #f9f9f9;

    --gray-color:           #909090;
    --link-color:           #404040;
    --p-color:              #666262;

    --base-font-family:    'Dosis', sans-serif;
    --font-weight-bold:     bold;
    --font-weight-normal:   normal;
    --font-weight-light:    300;
    --font-weight-thin:     100;

    --h1-font-size:         48px;
    --h2-font-size:         36px;
    --h3-font-size:         28px;
    --h4-font-size:         24px;
    --h5-font-size:         22px;
    --h6-font-size:         22px;
    --p-font-size:          18px;
    --base-font-size:       16px;
    --menu-font-size:       14px;

    --border-radius-large:  100%;
    --border-radius-small:  2px;
  }


  body {
    font-size: 100%;
    background: #ffffff;
    background: var(--white-color);
    width: 100%;
    box-sizing: border-box;
    font-family: 'Dosis', sans-serif;
    margin:0;
    padding: 0;
  }

  html, body {
    overflow-x:hidden;
  } 

  /*---------------------------------------
     TYPOGRAPHY              
  -----------------------------------------*/

  h1,h2,h3,h4,h5,h6 {
    /*font-weight: var(--font-weight-thin);*/
    
    color: white;
    
  }

  h1 {

    font-family: 'Dosis', sans-serif;
    font-size: 48px;
    font-size: var(--h1-font-size);
    font-weight: bold;
    font-weight: var(--font-weight-bold);
    /*letter-spacing: -1px;*/
    text-transform: uppercase;
    
  }

  h2 {
    font-family: 'Dosis', sans-serif;
    font-size: 36px;
    font-size: var(--h2-font-size);
    font-weight: bold;
    font-weight: var(--font-weight-bold); 
  }

  h3 {
    font-family: 'Dosis', sans-serif;
    font-size: 28px;
    font-size: var(--h3-font-size);
    font-weight: bold;
    font-weight: var(--font-weight-bold); 
    margin: 0;
  }

  h4 {
    
    font-size: 24px;
    
    font-size: var(--h4-font-size);
    color: white;
  }

  h5 {
    font-size: 22px;
    font-size: var(--h5-font-size);
  }

  h6 {
    /*color: var(--gray-color);*/
    color: white;
    font-size: 22px;
    font-size: var(--h6-font-size);
    line-height: inherit;
    
  }

  p {

    font-family: 'Arvo', serif;
    color: white;
    /*color: var(--p-color);*/
    font-size: 18px;
    font-size: var(--p-font-size);
    font-weight: 300;
    font-weight: var(--font-weight-light);
    line-height: 1.5em;
  }

  b, 
  strong {
    font-weight: bold;
    font-weight: var(--font-weight-bold);
    /*letter-spacing: 0;*/
  }

  .section {
    padding: 7rem 0;
  }


  /* BUTTON */

  .custom-btn {
    background: transparent;
    border-radius: 2px;
    border-radius: var(--border-radius-small);
    padding: 14px 24px;
    color: #ffffff;
    color: var(--white-color);
    font-size: 14px;
    font-size: var(--menu-font-size);
    font-weight: normal;
    font-weight: var(--font-weight-normal);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    white-space: nowrap;
    transition: all 0.3s ease;
    border-radius: 8px;

  }

  .custom-btn:hover {
    color: #F0D290;
    color: var(--primary-color);
  }

  .custom-btn:focus {
    box-shadow: none;
  }

  .custom-btn.bordered:hover,
  .custom-btn.bordered:focus,

  .custom-btn.bg-color:hover,
  .custom-btn.bg-color:focus {
    background: #ffffff;
    background: var(--white-color);
    border-color: transparent;
    color: #F0D290;
    color: var(--primary-color);
  }

  .bordered {
    border: 1px solid #F0D290;
    border: 1px solid var(--primary-color);
    color: white;
  }

  .bg-color {
    background: #F0D290;
    background: var(--primary-color);
    color: #ffffff;
    color: var(--white-color);
  }

  /*---------------------------------------
     GENERAL               
  -----------------------------------------*/

  * {
    box-sizing: border-box;
  }

  *::before,
  *::after {
    box-sizing: border-box;
  }

  a {
    color: #404040;
    color: var(--link-color);
    font-weight: normal;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  a:hover, 
  a:active, 
  a:focus {
    color: #F0D290;
    color: var(--primary-color);
    outline: none;
    text-decoration: none;
  }

  /*---------------------------------------
     HOME            
  -----------------------------------------*/

.homedimensions{
    min-height: "50vh;"
  }

.webgif{
  display:flex;
  min-width: 100%;

}

.mobilegif{
  
  display: none !important;
}
  /*---------------------------------------
     STORY            
  -----------------------------------------*/
  .storydimensions{
    
    min-height: 50vh;
  }

  .storylayout{
        
    display:flex; 
    flex-direction: row; 
    justify-content: center;
    
  }

.customimage{

  padding: 2%; 
  margin-top: -2%;

}

.storyp{

  padding-left: 4.5%;

}

   /*---------------------------------------
     Statutes            
  -----------------------------------------*/

  .stautessection{
    
    width: 20vw;
  }

  .statutewrapper{
    
    width: 100%; 
    display: block; 
    margin: auto;

  }

   /*---------------------------------------
     Roadmap            
  -----------------------------------------*/

.roadmapcontainer{
  background-color: #869fd1; 
  height: 120vh;
  width: 100%;

}
.slide-in {
  -webkit-animation-name: slideMe;
          animation-name: slideMe;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}


@-webkit-keyframes slideMe {
  from {-webkit-transform: translateX(100%);transform: translateX(100%);}
  to {-webkit-transform: translateX(0%);transform: translateX(0%);}
  }


@keyframes slideMe {
  from {-webkit-transform: translateX(100%);transform: translateX(100%);}
  to {-webkit-transform: translateX(0%);transform: translateX(0%);}
  }

.sky-in{
  -webkit-animation-name: skyMe;
          animation-name: skyMe;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes skyMe {
    from {-webkit-transform: translateX(-100%);transform: translateX(-100%);}
    to {-webkit-transform: translateX(0%);transform: translateX(0%);}
    }

@keyframes skyMe {
    from {-webkit-transform: translateX(-100%);transform: translateX(-100%);}
    to {-webkit-transform: translateX(0%);transform: translateX(0%);}
    }

.tremble{
  -webkit-animation-name: trembleMe;
          animation-name: trembleMe;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes trembleMe {
  from {-webkit-transform: scale(1);transform: scale(1);}
  to {-webkit-transform: scale(1.08);transform: scale(1.08);}
  }

@keyframes trembleMe {
  from {-webkit-transform: scale(1);transform: scale(1);}
  to {-webkit-transform: scale(1.08);transform: scale(1.08);}
  }

.across {
  -webkit-animation-name: acrossMe;
          animation-name: acrossMe;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}


@-webkit-keyframes acrossMe {
  from {-webkit-transform: translateY(500px);transform: translateY(500px);}
  to {-webkit-transform: translateY(0%);transform: translateY(0%);}
  }


@keyframes acrossMe {
  from {-webkit-transform: translateY(500px);transform: translateY(500px);}
  to {-webkit-transform: translateY(0%);transform: translateY(0%);}
  }



/*
  @media (prefers-reduced-motion: reduce) {
      * {
        animation: none !important;
        transition: none !important;
      }

*/
/*
.tittleeffect{
      animation-name: titleanimation;
      animation-duration: 6s;
      animation-iteration-count: 3;
    }

@keyframes titleanimation {
      0% {color: white;}
      50% {color: var(--primary-color);}
      75% {color: white;}
      }
  


*/
@media screen and (max-width: 670px){


  .sign {
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    font-size: 28px !important;
    color: #F0D290;
    color: var(--primary-color);
    text-shadow: 0 0 0.6rem #d3f5c8, 0 0 1.5rem #a8f18c,
      -0.2rem 0.1rem 1rem #57b954, 0.2rem 0.1rem 1rem #439738,
      0 -0.5rem 2rem #24ff66, 0 0.5rem 3rem #26f813;
    -webkit-animation: shine 2s forwards, flicker 3s infinite;
            animation: shine 2s forwards, flicker 3s infinite;
  }
  
  @-webkit-keyframes blink {
    0%,
    22%,
    36%,
    75% {
      color: #ffe6ff;
      text-shadow: 0 0 0.6rem #d3f5c8, 0 0 1.5rem #a8f18c,
      -0.2rem 0.1rem 1rem #57b954, 0.2rem 0.1rem 1rem #439738,
      0 -0.5rem 2rem #24ff66, 0 0.5rem 3rem #26f813;
    }
    28%,
    33% {
      color: #F0D290;
      color: var(--primary-color);
      text-shadow: none;
    }
    82%,
    97% {
      color: #F0D290;
      color: var(--primary-color);
      text-shadow: none;
    }
  }
  
  @keyframes blink {
    0%,
    22%,
    36%,
    75% {
      color: #ffe6ff;
      text-shadow: 0 0 0.6rem #d3f5c8, 0 0 1.5rem #a8f18c,
      -0.2rem 0.1rem 1rem #57b954, 0.2rem 0.1rem 1rem #439738,
      0 -0.5rem 2rem #24ff66, 0 0.5rem 3rem #26f813;
    }
    28%,
    33% {
      color: #F0D290;
      color: var(--primary-color);
      text-shadow: none;
    }
    82%,
    97% {
      color: #F0D290;
      color: var(--primary-color);
      text-shadow: none;
    }
  }
  
  .flicker {
    -webkit-animation: shine 2s forwards, blink 3s 2s infinite;
            animation: shine 2s forwards, blink 3s 2s infinite;
  }
  
  .fast-flicker {
    -webkit-animation: shine 2s forwards, blink 10s 1s infinite;
            animation: shine 2s forwards, blink 10s 1s infinite;
  }
  
  @-webkit-keyframes shine {
    0% {
      color: #F0D290;
      color: var(--primary-color);
      
      text-shadow: none;
    }
    100% {
      color: white;
      text-shadow: 0 0 0.6rem #d3f5c8, 0 0 1.5rem #a8f18c,
      -0.2rem 0.1rem 1rem #57b954, 0.2rem 0.1rem 1rem #439738,
      0 -0.5rem 2rem #24ff66, 0 0.5rem 3rem #26f813;
    }
  }
  
  @keyframes shine {
    0% {
      color: #F0D290;
      color: var(--primary-color);
      
      text-shadow: none;
    }
    100% {
      color: white;
      text-shadow: 0 0 0.6rem #d3f5c8, 0 0 1.5rem #a8f18c,
      -0.2rem 0.1rem 1rem #57b954, 0.2rem 0.1rem 1rem #439738,
      0 -0.5rem 2rem #24ff66, 0 0.5rem 3rem #26f813;
    }
  }
  
  @-webkit-keyframes flicker {
    from {
      opacity: 1;
    }
  
    4% {
      opacity: 0.9;
    }
  
    6% {
      opacity: 0.85;
    }
  
    8% {
      opacity: 0.95;
    }
  
    10% {
      opacity: 0.9;
    }
  
    11% {
      opacity: 0.922;
    }
  
    12% {
      opacity: 0.9;
    }
  
    14% {
      opacity: 0.95;
    }
  
    16% {
      opacity: 0.98;
    }
  
    17% {
      opacity: 0.9;
    }
  
    19% {
      opacity: 0.93;
    }
  
    20% {
      opacity: 0.99;
    }
  
    24% {
      opacity: 1;
    }
  
    26% {
      opacity: 0.94;
    }
  
    28% {
      opacity: 0.98;
    }
  
    37% {
      opacity: 0.93;
    }
  
    38% {
      opacity: 0.5;
    }
  
    39% {
      opacity: 0.96;
    }
  
    42% {
      opacity: 1;
    }
  
    44% {
      opacity: 0.97;
    }
  
    46% {
      opacity: 0.94;
    }
  
    56% {
      opacity: 0.9;
    }
  
    58% {
      opacity: 0.9;
    }
  
    60% {
      opacity: 0.99;
    }
  
    68% {
      opacity: 1;
    }
  
    70% {
      opacity: 0.9;
    }
  
    72% {
      opacity: 0.95;
    }
  
    93% {
      opacity: 0.93;
    }
  
    95% {
      opacity: 0.95;
    }
  
    97% {
      opacity: 0.93;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes flicker {
    from {
      opacity: 1;
    }
  
    4% {
      opacity: 0.9;
    }
  
    6% {
      opacity: 0.85;
    }
  
    8% {
      opacity: 0.95;
    }
  
    10% {
      opacity: 0.9;
    }
  
    11% {
      opacity: 0.922;
    }
  
    12% {
      opacity: 0.9;
    }
  
    14% {
      opacity: 0.95;
    }
  
    16% {
      opacity: 0.98;
    }
  
    17% {
      opacity: 0.9;
    }
  
    19% {
      opacity: 0.93;
    }
  
    20% {
      opacity: 0.99;
    }
  
    24% {
      opacity: 1;
    }
  
    26% {
      opacity: 0.94;
    }
  
    28% {
      opacity: 0.98;
    }
  
    37% {
      opacity: 0.93;
    }
  
    38% {
      opacity: 0.5;
    }
  
    39% {
      opacity: 0.96;
    }
  
    42% {
      opacity: 1;
    }
  
    44% {
      opacity: 0.97;
    }
  
    46% {
      opacity: 0.94;
    }
  
    56% {
      opacity: 0.9;
    }
  
    58% {
      opacity: 0.9;
    }
  
    60% {
      opacity: 0.99;
    }
  
    68% {
      opacity: 1;
    }
  
    70% {
      opacity: 0.9;
    }
  
    72% {
      opacity: 0.95;
    }
  
    93% {
      opacity: 0.93;
    }
  
    95% {
      opacity: 0.95;
    }
  
    97% {
      opacity: 0.93;
    }
  
    to {
      opacity: 1;
    }
  }


  /*---------------------------------------
     TYPOGRAPHY              
  -----------------------------------------*/

  h1 {
    font-size: 25px;
  }

  h3{

    font-size: 16px;
  }

  h4{
    font-size: 18px;
  }

  h5 {
    font-size: 17px;
  }

  h6 {

    font-size: 10px;

  }

  p {

    font-size: 15px;

  }


  /*---------------------------------------
     HOME            
  -----------------------------------------*/
.homedimensions{
    min-height: 50vh;
  }


.mobilegif{
    display:flex !important;
    max-width: 100% !important;  
  }
  
.webgif{
    visibility: hidden !important; 
    display: none !important;
  }

 
  /*---------------------------------------
     STORY            
  -----------------------------------------*/
  .storydimensions{
    min-height: 100vh;
  }

  .storylayout{
    /*min-height: 40vh; */
    display: flex; 
    flex-direction: row; 
    justify-content: center;
  }

  .customimage{
    width: 50%;
  }

  .storyp{

    margin: 15px;
    padding-left: 0%;

  }


   /*---------------------------------------
     Statutes            
  -----------------------------------------*/

  .stautessection{
    width: 80%;
    padding: 3%;
  }
  
  .statutewrapper{
    
    width: 80%; 
    display: block; 
    margin: auto;
    padding: 3%;

  }
  
   /*---------------------------------------
     Roadmap            
  -----------------------------------------*/

}


body {
    margin: 0;
    padding: 0;
    background: #869fd1;
}


.spinner-wrapper{
  width: 100%;
  height: 100%;
  background-color: #9baeda;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  position: relative;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}

.spinner::before,
.spinner:after{
  content: "";
  position: absolute;
  border-radius: 50%;
}

.spinner:before {
  width: 100%;
  height: 100%;
  background-image:linear-gradient(90deg, white 0%, #F0D290 100% );
  -webkit-animation: spin .5s infinite linear;
          animation: spin .5s infinite linear;
}
.spinner:after {
  width: 90%;
  height: 90%;
  background-color: #9baeda;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@-webkit-keyframes spin {
  to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
  }
}


.social-media-icon:hover{
  
  visibility:visible;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}






.sign {
  width: 100%;
  height: 100%;
  letter-spacing: 2;
  text-transform: uppercase;
  font-size: 48px;
  font-size: var(--h1-font-size);
  color: #F0D290;
  color: var(--primary-color);
  text-shadow: 0 0 0.6rem #d3f5c8, 0 0 1.5rem #a8f18c,
    -0.2rem 0.1rem 1rem #57b954, 0.2rem 0.1rem 1rem #439738,
    0 -0.5rem 2rem #24ff66, 0 0.5rem 3rem #26f813;
  -webkit-animation: shine 2s forwards, flicker 3s infinite;
          animation: shine 2s forwards, flicker 3s infinite;
}

@-webkit-keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #d3f5c8, 0 0 1.5rem #a8f18c,
    -0.2rem 0.1rem 1rem #57b954, 0.2rem 0.1rem 1rem #439738,
    0 -0.5rem 2rem #24ff66, 0 0.5rem 3rem #26f813;
  }
  28%,
  33% {
    color: #F0D290;
    color: var(--primary-color);
    /*color: white;*/
    text-shadow: none;
  }
  82%,
  97% {
    color: #F0D290;
    color: var(--primary-color);
    /*color:white;*/
    text-shadow: none;
  }
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #d3f5c8, 0 0 1.5rem #a8f18c,
    -0.2rem 0.1rem 1rem #57b954, 0.2rem 0.1rem 1rem #439738,
    0 -0.5rem 2rem #24ff66, 0 0.5rem 3rem #26f813;
  }
  28%,
  33% {
    color: #F0D290;
    color: var(--primary-color);
    /*color: white;*/
    text-shadow: none;
  }
  82%,
  97% {
    color: #F0D290;
    color: var(--primary-color);
    /*color:white;*/
    text-shadow: none;
  }
}

.flicker {
  -webkit-animation: shine 2s forwards, blink 3s 2s infinite;
          animation: shine 2s forwards, blink 3s 2s infinite;
}

.fast-flicker {
  -webkit-animation: shine 2s forwards, blink 10s 1s infinite;
          animation: shine 2s forwards, blink 10s 1s infinite;
}

@-webkit-keyframes shine {
  0% {
    color: #F0D290;
    color: var(--primary-color);
    /*color: white;*/
    text-shadow: none;
  }
  100% {
    color: white;
    text-shadow: 0 0 0.6rem #d3f5c8, 0 0 1.5rem #a8f18c,
    -0.2rem 0.1rem 1rem #57b954, 0.2rem 0.1rem 1rem #439738,
    0 -0.5rem 2rem #24ff66, 0 0.5rem 3rem #26f813;
  }
}

@keyframes shine {
  0% {
    color: #F0D290;
    color: var(--primary-color);
    /*color: white;*/
    text-shadow: none;
  }
  100% {
    color: white;
    text-shadow: 0 0 0.6rem #d3f5c8, 0 0 1.5rem #a8f18c,
    -0.2rem 0.1rem 1rem #57b954, 0.2rem 0.1rem 1rem #439738,
    0 -0.5rem 2rem #24ff66, 0 0.5rem 3rem #26f813;
  }
}

@-webkit-keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}


 /*---------------------------------------
     FAQs            
  -----------------------------------------*/
  #FAQs {
    background-color: #9baeda;
  }
  .accordion {
    
    width: 75vw;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    background: rgba(175, 134, 134, 0.1);
    
  }
  
  .accordion__label,
  .accordion__content {
    padding: 14px 20px;
  }
  
  .accordion__label {
    display: block;
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    font-size: 20px;
    transition: background 0.1s;
  }
  
  .accordion__label:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .accordion__label::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 20px;
    width: 12px;
    height: 6px;
    background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23FFFFFF99;" /></svg>');
    background-size: contain;
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
  }
  
  .accordion__content {
    background: rgba(151, 224, 117, 0.1);
    line-height: 1.6;
    font-size: 0.85em;
    display: none;
  }
  
  .accordion__input {
    display: none;
  }
  
  .accordion__input:checked ~ .accordion__content {
    display: block;
  }
  
  .accordion__input:checked ~ .accordion__label::after {
    -webkit-transform: translateY(-50%) rotate(0.5turn);
            transform: translateY(-50%) rotate(0.5turn);
  }
  

